import "./style.scss";

export default async function Page({}) {
  return <>{}</>;
}

/*
export const viewport: Viewport = {
  initialScale: 1,
  maximumScale: 1,
  width: "device-width",
  height: "device-height",
  viewportFit: "cover",
};

export const metadata: Metadata = {
  title: "Precious Crystal Candles - Ignis Immensus",
  description:
    "Onyx candle jars, scented soy wax candles, tools, accessories, and gifts! <3",
};
 */
